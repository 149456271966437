<script lang="ts" setup>
import { useFlightCategoriesStore } from '@/store/flightCategories';
import { useHootDestinationsStore } from '@/store/hootDestinations';
import { VForm } from 'vuetify/components/VForm';

type Model = {
  country: string
  city: string
  destinationCode: string
  price: string
  description: string
  priceCurrency: string
  imageUrl: File | null
  categoriesIds: number[]
}

const INITIAL_MODEL = {
  country: '',
  city: '',
  destinationCode: '',
  price: '',
  description: '',
  priceCurrency: '',
  imageUrl: null,
  categoriesIds: [],
}

const model = ref<Model>({
  country: '',
  city: '',
  destinationCode: '',
  price: '',
  description: '',
  priceCurrency: '',
  imageUrl: null,
  categoriesIds: [],
})


const isLoading = ref(false)
const hootDestinationsStore = useHootDestinationsStore()
const flightCategoriesStore = useFlightCategoriesStore()
const destinationCodes = computed(() => hootDestinationsStore.destinationCodes)
const isDialogVisible = computed(() => hootDestinationsStore.forms.edit.isDialogVisible)
const destinationId = computed(() => hootDestinationsStore.forms.edit.id)
const flightCategories = computed(() => flightCategoriesStore.categories)
const refVForm = ref<VForm>()

const onClose = () => {
  hootDestinationsStore.forms.edit.isDialogVisible = false
}

watch(() => isDialogVisible.value && destinationId.value, async (newVal) => {
  if (newVal) {
    isLoading.value = true
    const fetchedDestination = await hootDestinationsStore.getHootDestinationById(newVal)
    await flightCategoriesStore.getCategories()

    console.log({ fetchedDestination })
    const newModel = {
      ...fetchedDestination,
      categoriesIds: fetchedDestination.categories.map((category: any) => category.id),
      imageUrl: null,
    }

    // if (fetchedDestination.imageUrl) {
    //   newModel.imageUrl = await createFileFromUrl(fetchedDestination.imageUrl)
    // }

    model.value = newModel
    isLoading.value = false
  }
})

onMounted(() => {
  console.log('mounted')
})

async function handleEditHootDestination() {
  const res = await hootDestinationsStore.updateHootDestination(model.value)

  hootDestinationsStore.forms.edit.isDialogVisible = false
  model.value = INITIAL_MODEL
  refVForm.value?.reset()
}

const onSubmit = () => {
  refVForm.value?.validate()
    .then(({ valid: isValid }) => {
      if (isValid) {
        handleEditHootDestination()
      }
    })
    .catch(error => {
      console.error('Validation error:', error)
    })
}
</script>

<template>
  <VDialog v-model="isDialogVisible" max-width="600" :loading="isLoading">
    <!-- Dialog close btn -->
    <DialogCloseBtn @click="onClose" />

    <!-- Dialog Content -->
    <VCard title="Hot Destination">
      <VCardText>
        <VForm ref="refVForm" @submit.prevent="onSubmit">
          <VRow>
            <VCol cols="12">
              <VFileInput v-model="model.imageUrl" label="Image" />
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Country -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="country">Country</label>
                </VCol>

                <VCol cols="12" md="9">
                  <AppTextField id="country" v-model="model.country" placeholder="Enter country"
                    :rules="[requiredValidator]" persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 City -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="city">City</label>
                </VCol>

                <VCol cols="12" md="9">
                  <AppTextField id="city" v-model="model.city" placeholder="Enter city" :rules="[requiredValidator]"
                    persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Description -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="description">Description</label>
                </VCol>

                <VCol cols="12" md="9">
                  <VTextarea id="description" v-model="model.description" placeholder="Enter description"
                    :rules="[requiredValidator]" persistent-placeholder rows="4" />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Categories -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="categoriesIds">Categories</label>
                </VCol>

                <VCol cols="12" md="9">
                  <VSelect id="categoriesIds" multiple v-model="model.categoriesIds" :items="flightCategories"
                    :rules="[requiredValidator]" item-title="name" item-value="id" placeholder="Select categories"
                    persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Destination Code -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="destinationCode">Destination Code</label>
                </VCol>

                <VCol cols="12" md="9">
                  <VSelect id="destinationCode" v-model="model.destinationCode" :items="destinationCodes"
                    :rules="[requiredValidator]" item-title="code" item-value="code"
                    placeholder="Select destination code" persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Price -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="price">Price</label>
                </VCol>

                <VCol cols="12" md="9">
                  <AppTextField id="price" v-model="model.price" type="number" placeholder="100"
                    :rules="[requiredValidator]" persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Price Currency -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="priceCurrency">Price Currency</label>
                </VCol>

                <VCol cols="12" md="9">
                  <VSelect id="priceCurrency" v-model="model.priceCurrency" :items="['USD', 'NIS']"
                    :rules="[requiredValidator]" placeholder="Select price currency" persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>
          </VRow>

          <div class="d-flex justify-end flex-wrap gap-3 mt-8">
            <VBtn variant="tonal" color="secondary" @click="onClose">
              Close
            </VBtn>
            <VBtn type="submit">
              Save
            </VBtn>
          </div>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style scoped>
.create-btn {
  align-self: flex-start;
}
</style>
