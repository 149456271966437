import _definePage_default_0 from '/opt/atlassian/pipelines/agent/build/admin/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/atlassian/pipelines/agent/build/admin/src/pages/login.vue?definePage&vue'
import _definePage_default_3 from '/opt/atlassian/pipelines/agent/build/admin/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_4 from '/opt/atlassian/pipelines/agent/build/admin/src/pages/pages/account-settings/[tab].vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/flights-categories',
    name: 'flights-categories',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/flights-categories.vue'),
    /* no children */
  },
  {
    path: '/hoot-destinations',
    name: 'hoot-destinations',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/hoot-destinations.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/pages',
    /* internal name: 'pages' */
    /* no component */
    children: [
      {
        path: 'account-settings',
        /* internal name: 'pages-account-settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-account-settings-tab',
            component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/pages/account-settings/[tab].vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      }
    ],
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/settings.vue'),
    /* no children */
  },
  {
    path: '/sign-up',
    /* internal name: 'sign-up' */
    /* no component */
    children: [
      {
        path: ':slug(.*)',
        name: 'sign-up$slug',
        component: () => import('/opt/atlassian/pipelines/agent/build/admin/src/pages/sign-up/[...slug].vue'),
        /* no children */
      }
    ],
  }
]
